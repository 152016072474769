<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Category</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="#1c5580"
        class="mb-5"
        x-small
        large
        @click="openDialogCategory"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Add Category</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search Name"
              v-model="data_search"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cover</th>
                    <th scope="col">Name</th>
                    <th scope="col">Courses</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(category, i) in filteredCategories">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="" style="width: 20%">
                        <!-- <img v-if="category.cover" :src="category.cover.en" class="w-50" /> -->
                        <v-img
                          v-if="category.cover"
                          :src="category.cover.en"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>{{ category.name.en }}</td>
                      <td>
                        <template v-for="(course, i) in category.courses">
                          <b-media class="mb-1" :key="i">
                            <template v-slot:aside>
                              <v-img
                                v-if="course.cover"
                                :src="course.cover.en"
                                max-width="64"
                                max-height="40"
                                contain
                              ></v-img>
                            </template>
                            <h5 class="mt-0 mb-1 font-weight-bold">
                              {{ course.name.en }}
                            </h5>
                            <p class="mb-0 email-teacher">
                              <span class="mr-2">{{ course.entry_level }}</span>
                              <v-icon>mdi-arrow-right-bold-outline</v-icon>
                              <span class="ml-2">{{
                                course.end_of_course_level
                              }}</span>
                            </p>
                          </b-media>
                        </template>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                x-small
                                color="#1c5580"
                                v-bind="attrs"
                                v-on="on"
                                @click="detailCategory(i, category.id)"
                                ><v-icon color="white"
                                  >mdi-square-edit-outline</v-icon
                                ></v-btn
                              >
                            </template>
                            <span>Edit Category</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                x-small
                                color="#1c5580"
                                v-bind="attrs"
                                v-on="on"
                                class="ml-4"
                                @click="deleteCategory(category.id)"
                                ><v-icon color="white"
                                  >mdi-delete</v-icon
                                ></v-btn
                              >
                            </template>
                            <span>Delete Category</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!categories.length">
                    <tr>
                      <td style="text-align: center" colspan="5">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogCategory" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_category === 'add'" class="headline"
                >Add Category</span
              >
              <span v-if="type_form_category === 'update'" class="headline"
                >Edit Category</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCategory = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7">
                  <label style="font-size: 16px">Language</label>
                  <v-radio-group v-model="language" row class="mb-0 pb-0">
                    <v-radio label="English" value="en"></v-radio>
                    <v-radio label="Việt Nam" value="vi"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Name"
                    v-if="language === 'en'"
                    v-model="name_input.en"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Name"
                    v-if="language === 'vi'"
                    v-model="name_input.vi"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Courses</label>
                  <v-autocomplete
                    v-model="course_ids"
                    :disabled="isUpdating"
                    :items="course_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.en"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeCourseTag(data.item)"
                      >
                        {{ data.item.name.en }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name.en"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Description</label>
                  <!-- <template v-if="$v.description_input.$error">
                    <span style="display: block" class="error-validation" v-if="!$v.description_input.required">Description is required</span>
                  </template> -->
                  <Editor
                    v-if="language === 'en'"
                    v-model="description_input.en"
                  ></Editor>
                  <Editor
                    v-if="language === 'vi'"
                    v-model="description_input.vi"
                  ></Editor>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    small-chips
                    label="Cover"
                    @change="onElementChange"
                  ></v-file-input>
                  <v-img v-if="language === 'en'" :src="cover_input.en"></v-img>
                  <v-img v-if="language === 'vi'" :src="cover_input.vi"></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogCategory = false"
              >Close</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              v-if="type_form_category === 'add'"
              @click="btnAddOrEditCourse(1)"
              >Add</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              v-if="type_form_category === 'update'"
              @click="btnAddOrEditCourse(2)"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogDescription" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Description</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDescription = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <div v-html="descriptionHTML"></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDescription = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "CategoryList",
  components: {
    Loading: () => import("vue-loading-overlay"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  data() {
    return {
      is_call_api: false,
      type_form_category: null,
      data_search: "",
      categories: [],
      dialogDescription: false,
      descriptionHTML: null,
      dialogCategory: false,
      name_input: null,
      description_input: null,
      cover_input: null,
      cover_file: null,
      idCategory: null,
      course_ids: [],
      course_tags: [],
      language: "en",
      isUpdating: false,
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((category) => {
        return category.name.en
          .toLowerCase()
          .includes(this.data_search.toLowerCase());
      });
    },
  },
  created() {
    this.is_call_api = true;
    this.defaultDataCategory();
    this.getAllCourse();
    this.getAllCategory();
    this.is_call_api = false;
  },
  methods: {
    async getAllCategory() {
      let seft = this;
      await ApiService.get("prep-app/category?with_courses=1").then(function (
        response
      ) {
        if (response.status == 200) {
          seft.categories = response.data;
        }
      });
    },
    async getAllCourse() {
      let seft = this;
      await ApiService.get("prep-app/courses").then(function (response) {
        if (response.status == 200) {
          seft.course_tags = response.data;
        }
      });
    },
    removeCourseTag(item) {
      let index = this.course_ids.indexOf(item.id);
      if (index >= 0) this.course_ids.splice(index, 1);
    },
    defaultDataCategory() {
      this.language = "en";
      this.name_input = {
        en: "",
        vi: "",
      };
      this.description_input = {
        vi: "",
        en: "",
      };
      this.cover_input = {
        en: null,
        vi: null,
      };
      this.cover_file = {
        en: null,
        vi: null,
      };
    },
    openDialogCategory() {
      this.dialogCategory = true;
      this.type_form_category = "add";
      this.course_ids = [];
      this.defaultDataCategory();
    },
    detailCategory(i, id_category) {
      this.dialogCategory = true;
      this.type_form_category = "update";
      let course_ids = this.categories[i].courses.map((course) => {
        return course.id;
      });
      this.course_ids = course_ids;
      this.idCategory = id_category;
      this.name_input = this.categories[i].name;
      this.description_input = this.categories[i].description;
      this.cover_input.en = this.categories[i].cover.en;
      this.cover_input.vi = this.categories[i].cover.vi;
    },
    deleteCategory(id_category) {
      let self = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/category/" + id_category)
            .then(function (res) {
              if (res.status == 202) {
                self.getAllCategory();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async btnAddOrEditCourse(type) {
      let self = this;
      let formData = new FormData();
      formData.append("name", JSON.stringify(this.name_input));
      formData.append("description", JSON.stringify(this.description_input));
      formData.append("course_ids", this.course_ids);
      if (this.cover_file.en != null) {
        formData.append("cover_en", this.cover_file.en);
      }
      if (this.cover_file.vi != null) {
        formData.append("cover_vi", this.cover_file.vi);
      }
      if (type == 1) {
        await ApiService.post("prep-app/category", formData)
          .then(function (res) {
            if (res.status == 201) {
              self.getAllCategory();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              self.dialogCategory = false;
            }
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              self.showError(data_response_error);
            }
          });
      } else {
        await ApiService.post("prep-app/category/" + self.idCategory, formData)
          .then(function (res) {
            if (res.status == 202) {
              self.getAllCategory();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              self.dialogCategory = false;
            }
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              self.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    onElementChange(file) {
      let vm = this;
      if (file == undefined) {
        if (vm.language == "en") {
          vm.cover_input.en = null;
          vm.cover_file.en = null;
        }
        if (vm.language == "vi") {
          vm.cover_input.vi = null;
          vm.cover_file.vi = null;
        }
        return;
      }

      let reader = new FileReader();
      reader.onload = (e) => {
        if (vm.language == "en") {
          vm.cover_input.en = e.target.result;
          vm.cover_file.en = file;
        }
        if (vm.language == "vi") {
          vm.cover_input.vi = e.target.result;
          vm.cover_file.vi = file;
        }
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #136495 !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.description-teacher {
  max-width: 150px;
  max-height: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.email-teacher {
  font-style: italic;
  font-weight: bold;
  color: gray;
}
</style>
